.item-img {
  width: 80%;
}
.img-title {
  padding: 1rem 2rem 1rem 2rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
  display: grid;
  grid-template-columns: 1fr 3fr;
  border-bottom: 1px solid rgb(224, 219, 219);
}

@media (max-width: 26em) {
  .item-img {
    width: 100%;
  }
  .img-title {
    padding: 1rem 1rem 1rem 1rem;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    display: grid;
    gap: 3rem;
    grid-template-columns: 1fr;
    border-bottom: none;
  }
}
