

.item-img{
  width: 80%;
}
.img-title{
  padding: 1rem 2rem 1rem 2rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
  display: grid;
  grid-template-columns: 1fr 3fr;
  border-bottom: 1px solid rgb(224, 219, 219);
}



