.grid {
  width: 20rem;
  margin-top: 8rem;
  margin-bottom: 5rem;
  margin-left: 25%;
  gap: 10rem;
  display: grid;
  grid-template-columns: 6fr 2fr;
}
.cart-totals {
  display: grid;
  background-color: rgb(246, 238, 239);
  padding: 4rem 2rem 0rem 2rem;
  height: 44rem;
  position: relative;
}
.cart-totals:before {
  position: absolute;
  top: -10px;
  left: 0;
  content: '';
  display: block;
  height: 10px;
  width: 100%;
  background-color: transparent;
  background-size: 20px 20px;
  background-position: 0 10px, 40px 40px;
  background-image: radial-gradient(
    closest-side,
    transparent 9px,
    rgb(246, 238, 239) 100%
  );
}

.cart-totals-fixed {
  height: 20rem;
  width: 25rem;
  display: grid;
}
.total {
  font-weight: 700;
  font-size: 1.4rem;
}
.button {
  margin-bottom: 1rem;
  align-self: flex-end;
  justify-self: center;
}
.form-display {
  gap: 0.2rem;
  display: flex;
  flex-direction: column;
}
abbr {
  color: red;
}
.main {
  display: grid;
  gap: 2rem;
}
input[type='text'] {
  background: transparent;
}
.input {
  font-size: 1rem;
  height: 1.8rem;
  width: 20rem;
  border-top-style: hidden;
  border-right-style: hidden;
  border-left-style: hidden;
  border-bottom-style: groove;
  background-color: #eee;
  border: 1px solid rgb(24, 23, 23);
}
input:-webkit-autofill,
input:-webkit-autofill:focus {
  transition: background-color 600000s 0s, color 600000s 0s;
}
input:focus {
  outline: none;
}
select {
  font-size: 1rem;
  height: 1.9rem;
  width: 20rem;
  margin-top: 0.5rem;
}
.invalid label {
  color: #ca3e51;
}

.invalid input {
  border-color: #aa0b20;
  background-color: #ffeff1;
}
.actions {
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
}

.actions .submit {
  border: 1px solid #5a1a01;
  background-color: #5a1a01;
  color: white;
}

.actions .submit:hover,
.actions .submit:active {
  background-color: #7a2706;
}
.comment {
  color: #ca3e51;
}
.noitems {
  color: #ca3e51;
  margin-top: 10rem;
  font-family: 'Marck Script', cursive;
  font-size: 1.2rem;
  font-weight: 600;
}

/* MOBILE FORMAT */
/* IPHONE SE */
@media (max-width: 26em) {
  .grid {
    grid-template-columns: 1fr;
    margin-top: 3rem;
    margin-bottom: 3rem;
    margin-left: 10%;
  }
  .cart-totals{
height: 35rem;
width: 21rem;
  }
  .button{
margin-right: 8rem;
  }
}
