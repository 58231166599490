.circle {
  margin-left: -0.3rem;
  text-align: center;
  background-color: black;
  color: white;
  border-radius: 50%;
  padding: 0.1rem;
  width: 1.7rem;
  height: 1.7rem;
  border: 1px solid rgb(167, 162, 162);
}
.cart {
  height: 2.5rem;
  margin-top: 0.5rem;
  position: relative;
}
.main-nav-cart-details {
  display: none;
}
.cart:hover + .main-nav-cart-details {
  display: block;
}
.main-nav-cart-details:hover {
  display: block;
}

.cart-details {
  padding-top: 3rem;
  padding-bottom: 2rem;
  display: grid;
  grid-template-columns: 1fr;
  right: 2rem;
  width: 20rem;
  background-color: white;
  position: absolute;
  box-shadow: 0 2.4rem 4.8rem rgba(0.075, 0.075, 0.075, 0.075);
  border-top: 2px solid rgb(24, 23, 23);
  z-index: 100;
}
/* .cart-details::after{

  content: "";
  position: absolute;
  bottom: 100%;
  right: 44%;
  margin: auto;
  border-width: 7px;
  border-style: solid;
  border-color: transparent transparent black transparent;
} */
.checkout {
  align-self: center;
  justify-self: center;
}
.amount {
  align-self: center;
  justify-self: center;
  font-weight: bold;
}
.viewcart{
  align-self: center;
  justify-self: center;
}

.button-viewcart {
 
  min-width: 230px;
  height: 35px;
  margin: 0.5rem;
  padding: 0rem;
  letter-spacing: 0.5px;
  font-size: 13px;
  background-color: rgb(213, 207, 207);
  color: black;
  text-transform: uppercase;
  font-family: 'Open Sans Condensed';
  font-weight: bolder;
  border: none;
  cursor: pointer;
  justify-content: center;
}

.button-viewcart:hover {
  background-color: white; 
  color: black;
  border: 1px solid black;
}
.button-checkout {
  /* display: inline-block; */
  min-width: 230px;
  height: 35px;
  height: 40px;
  margin: 0.5rem;
  padding: 0rem;
  letter-spacing: 0.5px;
  font-size: 13px;
  background-color: black;
  color: white;
  text-transform: uppercase;
  font-family: 'Open Sans Condensed';
  font-weight: bolder;
  border: none;
  cursor: pointer;
  justify-content: center;
}

.button-checkout:hover {
  background-color: white; 
  color: black;
  border: 1px solid black;
}

.main{
  display: grid;
  grid-template-columns: 1fr 1fr;
}
.bump {
  animation: bump 300ms ease-out;
}

@keyframes bump {
  0% {
    transform: scale(1);
  }
  10% {
    transform: scale(0.9);
  }
  30% {
    transform: scale(1.1);
  }
  50% {
    transform: scale(1.15);
  }
  100% {
    transform: scale(1);
  }
}


@media (max-width: 26em) {
  .cart-details{
    width: 22rem;
  }
 
}