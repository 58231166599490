.delivery-description {
  height: 40rem;
}
.delivery-description-text-box {
  display: grid;
  row-gap: 2rem;
  margin: 1rem auto;
  height: 5rem;
  width: 50rem;
  text-align: start;
}
.delivery-heading-primary {
  margin-top: 10rem;
}
/* MOBILE FORMAT */
/* IPHONE SE */

@media (max-width: 26em) {
  .delivery-heading-primary {
    margin-top: 1rem;
  }
  .delivery-description-text-box{
    margin: 1rem auto;
    width: 20rem;
  }
  .delivery-description {
    height: 30rem;
  }
}