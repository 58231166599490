
* {
  padding: 0;
  margin: 0;
  
}
html {
  padding: 0;
  margin: 0;
  font-size: 90%;
  overflow-x: hidden;
}
.main{
  background-color:#fdf2e9;
  height: 90rem;
}
.cta-form {
  background-color: rgb(181, 177, 177);
  padding: 2rem;
  border-radius: 1rem;
  position: absolute;
  top: 10%;
  left: 50%;
  transform: translate(-50%, 50%);
  width: 500px;
  height: 400px;
  display: grid;
  grid-template-columns: 1fr;
  row-gap: 2.4rem;
}

.cta-form label {
  display: block;
  font-size: 1.4rem;
  font-weight: 500;
  margin-bottom: 0.5rem;
}
.cta-form input {
  width: 100%;
  padding: 1.2rem;
  font-size: 1.8rem;
  font-family: inherit;
  color: inherit;
  border: none;
  background-color: white;
  border-radius: 9px;
}

.btn--form{
  display: inline-block;
  min-width: 10px;
  width: 20px;
  height: 40px;
  letter-spacing: 0.5px;
  font-size: 13px;
  background-color: black;
  color: white;
  text-transform: uppercase;
  font-family: 'Open Sans Condensed';
  font-weight: bolder;
  border: none;
  cursor: pointer;
  justify-content: center;
  width:8rem;
  justify-self: center;
}

.btn--form:hover {
  background-color: white; 
  color: rgb(222, 19, 19);
  border: 1px solid black;
}