.gallery {
  display: grid;
  grid-template-columns: 2fr 7fr;
  gap: 6rem;
  padding: 2rem;
  /* height: 180vh; */
}
.products {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 1.6rem;
}

.collapsible {
  display: grid;
  grid-template-columns: 1fr auto;
  gap: 2rem;
  margin-top: 2rem;
  margin-left: 2rem;
  font-size: 1.2rem;
}
/* MOBILE FORMAT */
/* IPHONE SE */

@media (max-width: 26em) {
  .gallery {
    grid-template-columns: 1fr;
    gap: 2rem;
  }
  .products {
    grid-template-columns: 1fr;
  }
  .collapsible {
    font-size: 1rem;
    margin-top: 0rem;
    margin-left: 2rem;
  }
}
