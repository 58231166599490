
.main{
  display: grid;
  grid-template-columns: 0.2fr 2fr 0.2fr;
  align-items: center;
  justify-items: center;
  gap: 1rem;
  padding: 4rem 3rem 3rem 3rem;
  /* position: fixed;
  width: 100%; */
}
.main-nav-list {
  list-style: none;
  display: flex;
  align-items:center;
  justify-items: center;
  gap: 4.8rem;
  font-family: 'Marck Script', cursive;
 
}
.main-nav-link{
  position: relative;
}
.main-nav-link:link,
.main-nav-link:visited {
  display: inline-block;
  text-decoration: none;
  color: #333;
  font-weight: 800;
  font-size: 2rem;
  transition: all 0.3s;
}
.main-nav-link:hover,
.main-nav-link:active {
  border-bottom: 2px solid rgb(186, 12, 12);
  transition: outline 0.6s linear;
}
.main-nav-link.nav-cta:link,
.main-nav-link.nav-cta:visited {
  padding: 1rem 1rem;
  border-radius: 9rem;
  color: #fff;
  background-color: #e67e22;
}
.main-nav-link.nav-cta:hover{
  background-color: #cf711f;
}
.main-nav-link.nav-cta:active {
  background-color: #cf711f;
}
.img{
  height: 4rem;
  border-radius: 2rem;
}
.title{
  font-weight: 1000;
  font-size: 4rem;
  font-family: 'Marck Script', cursive;
  text-decoration: none;
  color: black;
}
.align{
  text-align: center;
}

.main-nav-submenu{
  display: none;
}

.main-nav-link:hover + .main-nav-submenu {
  display: block;
}
.main-nav-submenu:hover{
  display: block;
}
.main-nav{
  justify-self:center;
}
.serch-cart-totalAmount{
align-self: center;
display: grid;
grid-template-columns: 1fr;
}
.link-search{
  text-decoration: none;
  color: black;
}
.message{
  margin: 2rem;
  text-align: center;
  color: brown;
  font-weight: 900;
}
/* MOBILE FORMAT */
/* IPHONE SE */

@media (max-width: 26em) {
  .main{
    grid-template-columns: 1fr 1fr;
    padding: 3rem;
    gap: 4rem;
  }
  .main-nav-link:link{
    font-size: 1.3rem;
  }
.main-nav-list{
  gap: 1.6rem;
}
.main-nav{
  grid-column: 1/3;
}
.serch-cart-totalAmount{
  grid-column: 2/3;
  grid-row: 1/2;
}
}
