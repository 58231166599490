.item {
  display: flex;
  justify-content: space-between;
  flex-shrink: 0;
  margin: 1rem;
  padding-bottom: 1rem;
  color: black;
}
.link{
  text-decoration: none;
  color: black;
  font-size: 1.5rem;
}