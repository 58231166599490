.footer {
  border-top: 1px solid black;
}

.container {
  /* max-width: 120rem; */
  padding: 0 3.2rem;
  margin: 0 auto;
  /* max-width: 130rem; */
}
.grid {
  display: grid;
  column-gap: 4.4rem;
}
.grid--footer {
  grid-template-columns: 1fr;
  align-items: center;
  justify-items: center;
}

.copyright {
  font-size: 1.2rem;
  line-height: 1.6;
  color: black;
  margin: 5rem 0 8rem 0;
}
.footer-link:link,
.footer-link:visited {
  text-decoration: none;
  font-size: 1.4rem;
  color: black;
  transition: all 0.3s;
}
.footer-link:hover,
.footer-link:active {
  color: #555;
}

.email,
.phone {
  color: rgb(186, 12, 12);
  width: 2rem;
  align-self: flex-end;
  justify-self: end;
}

.address-col {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  margin-top: 15rem;
  font-size: 1.4rem;
  gap: 1rem;
}
/* MOBILE FORMAT */
/* IPHONE SE */

@media (max-width: 26em) {
  .grid--footer {
    grid-template-columns: 1fr;
    gap: 2rem;
  }
  .address-col {
    grid-template-columns: 1fr 10fr;
    align-items: center;
    justify-items: center;
    margin-top: 1rem;
  }
  .contact {
    grid-column: 1/3;
    font-size: 1.2rem;
  }
  .phone,
  .phone-nr,
  .footer-link,
  .email {
    font-size: 1.2rem;
  }
  .phone,
  .email,
  .contact,
  .email-div {
    justify-self: flex-start;
  }
  .phone-nr,
  .footer-link {
    justify-self: flex-start;
  }
  .footer-link:link {
    font-size: 1.2rem;
  }
  .copyright {
    font-size: 0.9rem;
  }
}
