.grid{
  margin-top: 10rem;
  margin-bottom: 5rem;
  margin-left: 10rem;
  margin-right: 8rem;
  gap: 2rem;
  display: grid;
  grid-template-columns: 8fr 2fr;
  padding-bottom: 5rem;
  position: relative;
}
.cart-totals{
  display: grid;
  background-color: rgb(246, 238, 239);
  padding: 4rem 2rem 0rem 2rem;
  height: 42.5rem;
  position: relative;
}
.cart-totals:before {
  position: absolute;
  top: -10px;
  left: 0;
  content: "";
  display: block;
  height: 10px;
  width: 100%;
  background-color: transparent;
  background-size: 20px 20px;
  background-position: 0 10px,40px 40px;
  background-image: radial-gradient(closest-side,transparent 9px,rgb(246, 238, 239) 100%);
}
.cart-totals-fixed{
  height: 20rem;
  display: grid;
}
.total{
  font-weight:700;
  font-size: 1.4rem;
}
.button-checkout{
  width: 12rem;
  margin: 5rem;
  align-self: flex-end;
}

.product-item{
  display: grid;
  grid-template-columns: 0.1fr 10fr;
  gap: 2rem;
}
.btn{
  background-color: #fff;
  color: black;
  border: none;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 12px 24px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  font-size: 1.3rem;
  text-align: center;
  margin: 0rem;
}

.buttons{
  display: grid;
  grid-template-rows: 1fr 1fr;
  gap: 1rem;
  color: grey;
  width: 2rem;
  font-size: 1.2rem; 
}
.button-div-minus{
align-self: flex-end;
}
.button-div-plus{
align-self: flex-start;
}
.btn:hover{
  border: none;
}

/* MOBILE FORMAT */
/* IPHONE SE */
@media (max-width: 26em) {
  .grid{
    margin-top: 2rem;
    margin-bottom: 2rem;
    margin-left: 2rem;
    margin-right: 2rem;
    grid-template-columns: 1fr;
  }
  .product-item{
    margin: 2rem;
  }
 .cart-totals{
  width: 22rem;
 }
 .button-checkout{
  margin-left: 3rem;
 }
 .btn{
  height: 35px;
  width: 35px;
 }
 .add-remove{
  padding-top: 3rem;
  align-items:center;
  gap: 3rem;
 }
}