.section-description {
  padding: 4.8rem 0 9.6rem 0;
}
.description-text-box{
  border: 5px double black;
  padding: 2rem;
}
.description {
  max-width: 130rem;
  margin: 0 auto;
  padding: 0 3.2rem;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 9.6rem;
  align-items: center;
}

.main-description {
  font-size: 1.2rem;
  line-height: 1.5;
  
}
.crafts-img {
  width: 100%;
}
.heading-primary {
  font-weight: 700;
  font-size: 2rem;
  color: #333;
  font-family: 'Marck Script', cursive;
}
/* MOBILE FORMAT */
/* IPHONE SE */

@media (max-width: 26em) {
  .description{
    grid-template-columns: 1fr;
    gap: 2rem;
  }
  .description-text-box{
    grid-row: 2/3;
  }
.heading-primary{
  font-size: 1.7rem;
}
.main-description{
  font-size: 1rem;
}
}