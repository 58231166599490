.grid {
  display: grid;
  grid-template-columns: 1fr 5fr 8fr;
  gap: 2rem;
  margin: 3rem;
}

.img-multiple {
  width: 80%;
  justify-self: end;
}

.left-images {
  display: grid;
  grid-template-columns: 1fr;
  gap: 0.5rem;
}

.central-img {
  width: 83%;
  margin-bottom: 0.5rem;
}
.right-div {
  margin: 2rem;
}
.title,
.details {
  font-size: 1.2rem;
  font-weight: bold;
}
.price,
.description,
.details {
  margin-top: 2rem;
  margin-bottom: 2rem;
}
.details-list {
  margin-left: 1rem;
}
.sizes {
  width: 10rem;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 0.4rem;
  margin-bottom: 1rem;
  list-style: none;
}
.size {
  box-shadow: 0 0 0 0.7px rgb(0 0 0 / 20%);
  line-height: 24px;
  width: 3rem;
  height: 2rem;
  text-align: center;
  border: 1px solid rgb(145, 139, 139);
  cursor: pointer;
}
.size:hover {
  animation: bump 300ms ease-out;
  background-color: rgb(232, 224, 224);
}
.size-div {
  margin-top: 1rem;
  margin-bottom: 1rem;
}
.button {
  border: 2px solid rgb(189, 181, 181);
  width: 20rem;
}
.no-drop {
  cursor: no-drop;
}
.size-checked {
  border: 3px solid black;
}
.noitems {
  color: #ca3e51;
  margin-top: 2rem;
  font-family: 'Marck Script', cursive;
  font-size: 1.2rem;
  font-weight: 600;
}
/* MOBILE FORMAT */
/* IPHONE SE */
@media (max-width: 26em) {
  .grid {
    grid-template-columns: 1fr;
  }
  .central-div {
    align-self: start;
    margin-left: 2rem;
  }
  .img-multiple {
    justify-self: center;
  }
  .central-img {
    margin-top: 0rem;
    width: 89%;
  }
  .button {
    width: 13rem;
  }
  .dispalay-none{
    display: none;
  }
}
