* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

html {
  /* font-size: 10px;
    10px/16px = 0.625=62.5% */
  font-size: 62.5%;
  overflow-x: hidden;
  height: 100%;
}
body { min-height: 100%; }
.main {
  background-color: rgb(250, 250, 250);
  overflow-x: hidden;
  width: 100vw;
  height: 100vh;
}
