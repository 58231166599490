
.menu{
  height: 20rem;
  width: 30rem;
  background-color: white;
  position: absolute;
  box-shadow: 0 2.4rem 4.8rem rgba(0.075, 0.075, 0.075, 0.075);
  z-index: 100;
}

.message{
  margin: 2rem;
}